import React from "react";

const SignIn = React.lazy(() => import("./SignIn/index.jsx"));
const OtpCode = React.lazy(() => import("./SignIn/OtpCode/index.jsx"));
const ForgetPassword = React.lazy(() =>
  import("./SignIn/ForgetPassword/index.jsx")
);
const DoctorDashboard = React.lazy(() =>
  import("./Doctor/Dashboard/index.jsx")
);
const Appointment = React.lazy(() =>
  import("./Doctor/Appointment/fullCalendar.jsx")
);
const NoPageFound = React.lazy(() => import("./NoPageFound/index.jsx"));
const Patients = React.lazy(() =>
  import("./Doctor/PatientProfile/MyPatients.jsx")
);
const PatientsDetails = React.lazy(() =>
  import("./Doctor/PatientProfile/PatientDetails.jsx")
);
const TeleConsultation = React.lazy(() =>
  import("./Doctor/Appointment/TeleConsultation")
);
const Staff = React.lazy(() => import("./Doctor/Staff/Staff.jsx"));
const Chat = React.lazy(() => import("./Doctor/Chat/Chat.jsx"));
const UpdateChat = React.lazy(() => import("./Doctor/UpdateChat"));
const SuperadminCreatedSlots = React.lazy(() =>
  import("../components/Theme/SlotsById/Slots.jsx")
);
const AdminCreatedSlots = React.lazy(() =>
  import("../components/Theme/SlotsById/Slots.jsx")
);
const Profile = React.lazy(() => import("./Doctor/Profile/profile.jsx"));
const AppointmentNotesDetails = React.lazy(() =>
  import("./Doctor/PatientProfile/PatientAppointmentNotesDetails.jsx")
);
const AppointmentNotes = React.lazy(() =>
  import("./Doctor/Appointment/TeleConsultation/AppointmentNotes/index.jsx")
);
const StaffChat = React.lazy(() => import("./Doctor/Staff/StaffChat.jsx"));
const PatientPrescription = React.lazy(() => import("./Patient/Prescription"));
const PatientDashboard = React.lazy(() =>
  import("./Patient/Dashboard/index.jsx")
);
const PatientPaymentDetail = React.lazy(() =>
  import("./Patient/Payments/PaymentDetails.jsx")
);

const Signup = React.lazy(() => import("./Signup/index.jsx"));
const PatientAppointmentDetails = React.lazy(() =>
  import("./Patient/Appointment/fullCalendar.jsx")
);
const PatientTeleConsultation = React.lazy(() =>
  import("./Patient/Appointment/TeleConsultation/index.jsx")
);
const PatientProfile = React.lazy(() => import("./Patient/Profile/index.jsx"));

const SuperAdmin = React.lazy(() =>
  import("./SuperAdmin/UserManagement/index.jsx")
);
const DoctorDetails = React.lazy(() =>
  import("./Patient/DoctorSlots/DoctorDetails.jsx")
);
const SuperadminPaymentDetails = React.lazy(() =>
  import("./SuperAdmin/Payments/PaymentDetails.jsx")
);
const SuperadminSettings = React.lazy(() =>
  import("./SuperAdmin/Settings/index.jsx")
);
const CompleteProfile = React.lazy(() =>
  import("../components/ProfileLayout/CompleteProfile.jsx")
);
const PersonalInformation = React.lazy(() =>
  import("./Patient/CompleteProfile/PersonalInformation.jsx")
);
const SuperAdminDashboard = React.lazy(() =>
  import("./SuperAdmin/Dashboard/index.jsx")
);
const AdminDashboard = React.lazy(() => import("./Admin/Dashboard/index.jsx"));
const Admin = React.lazy(() => import("./Admin/UserManagement/index.jsx"));
const AdminPaymentdetails = React.lazy(() =>
  import("./Admin/PaymentDetails/index.jsx")
);
const MedicalHistory = React.lazy(() =>
  import("./Patient/CompleteProfile/MedicalHistory/index.jsx")
);
const Pharmacy = React.lazy(() =>
  import("./Patient/CompleteProfile/Pharmacy.jsx")
);
const ADMINDOCTORS = React.lazy(() =>
  import("./../components/Theme/DoctorsAppointment/index.jsx")
);
const SUPERADMINDOCTORS = React.lazy(() =>
  import("./../components/Theme/DoctorsAppointment/index.jsx")
);
const ADMINFEES = React.lazy(() =>
  import("./../components/Theme/FeesDataTable/index.jsx")
);
const SUPERADMINFEES = React.lazy(() =>
  import("./../components/Theme/FeesDataTable/index.jsx")
);
const ADMINQUESTIONNAIRE = React.lazy(() =>
  import("./SuperAdmin/Forms/FormsData/Questionnaires.jsx")
);
const SUPERADMINQUESTIONNAIRE = React.lazy(() =>
  import("./SuperAdmin/Forms/FormsData/Questionnaires.jsx")
);
const SUPERADMINFORMS = React.lazy(() => import("./SuperAdmin/Forms"));

const PatientPrescriptionForms = React.lazy(() =>
  import("./Patient/OnlinePrescriptionForms")
);
const DOCTORPRESCRIPTIONDASHBOARD = React.lazy(() =>
  import("./Doctor/OnlinePrescriptionForms")
);
const DOCTORPRESCRIPTIONFORM = React.lazy(() =>
  import("./Doctor/OnlinePrescriptionForms/PatientPrescription/index.jsx")
);
const TERMSANDCONDITIONS = React.lazy(() => import("./TermsAndConditions"));
const PRIVACYPOLICY = React.lazy(() => import("./PrivacyPolicy"));
const SUPERADMINPLANS = React.lazy(() =>
  import("./SuperAdmin/SubscriptionPlans")
);
const ADMINPLANS = React.lazy(() => import("./SuperAdmin/SubscriptionPlans"));
const SUPERADMINHOMETESTING = React.lazy(() =>
  import("./SuperAdmin/HomeTesing")
);
const SUPERADMINSTISCREENING = React.lazy(() =>
  import("./SuperAdmin/STIScreening")
);
const PATIENTPLANS = React.lazy(() => import("./Patient/MyPlans"));
const PATIENTHOMETESTING = React.lazy(() => import("./Patient/Testings"));
const PATIENTPURCHASEPLANS = React.lazy(() =>
  import("./Patient/MyPlans/PurchasePlans")
);
const PATIENTPURCHASETESTING = React.lazy(() =>
  import("./Patient/Testings/PurchaseTestings")
);
const DOCTORMEDICATIONFORM = React.lazy(() =>
  import(
    "./Doctor/OnlinePrescriptionForms/PatientPrescription/Medications/index.jsx"
  )
);
const SuperadminSlots = React.lazy(() =>
  import("../components/Theme/CreatedSlots")
);

const AdminSlots = React.lazy(() => import("../components/Theme/CreatedSlots"));

const WEB_PAGES = {
  SIGNIN: SignIn,
  OTPCODE: OtpCode,
  FORGETPASSWORD: ForgetPassword,
  DOCTOR_DASHBOARD: DoctorDashboard,
  APPOINTMENT: Appointment,
  TELE_CONSULTATION: TeleConsultation,
  PATIENTS: Patients,
  PATIENTDETAILS: PatientsDetails,
  STAFF: Staff,
  CHAT: Chat,
  DOCTOR_CHAT: UpdateChat,
  ADMIN_SLOTS: AdminSlots,
  SUPERADMIN_SLOTS: SuperadminSlots,
  SUPERADMIN_CREATED_SLOTS: SuperadminCreatedSlots,
  ADMIN_CREATED_SLOTS: AdminCreatedSlots,
  PROFILE: Profile,
  APPOINTMENTNOTESDETAILS: AppointmentNotesDetails,
  // PROFILEEDIT: ProfileEdit,
  APPOINTMENTNOTES: AppointmentNotes,
  STAFFCHAT: StaffChat,
  PATIENT_PRESCRIPTION: PatientPrescription,
  PATIENT_DASHBOARD: PatientDashboard,
  PATIENT_PAYMENTDETAILS: PatientPaymentDetail,
  SIGNUP: Signup,
  PATIENT_APPOINTMENTDETAILS: PatientAppointmentDetails,
  PATIENT_TELE_CONSULTATION: PatientTeleConsultation,
  PATIENT_PROFILE: PatientProfile,
  PATIENT_PRESCRIPTION_FORMS: PatientPrescriptionForms,
  PATIENT_PLANS: PATIENTPLANS,
  PATIENT_HOME_TESTING: PATIENTHOMETESTING,
  PATIENT_STI_SCREENING: PATIENTHOMETESTING,
  PATIENT_PURCHASE_PLANS: PATIENTPURCHASEPLANS,
  PATIENT_PURCHASE_HOME_TESTING: PATIENTPURCHASETESTING,
  PATIENT_PURCHASE_STI_SCREENING: PATIENTPURCHASETESTING,
  SUPERADMIN_USERMANAGEMENT: SuperAdmin,
  SUPERADMIN_DASHBOARD: SuperAdminDashboard,
  DOCTOR_DETAILS: DoctorDetails,
  SUPERADMIN_PAYMENTDETAILS: SuperadminPaymentDetails,
  SUPERADMIN_SETTINGS: SuperadminSettings,
  COMPLETE_PROFILE: CompleteProfile,
  PERSONAL_INFORMATION: PersonalInformation,
  ADMIN_DASHBOARD: AdminDashboard,
  ADMIN_USERMANAGEMENT: Admin,
  ADMIN_PAYMENTDETAILS: AdminPaymentdetails,
  MEDICAL_HISTORY: MedicalHistory,
  PHARMACY: Pharmacy,
  NO_PAGE_FOUND: NoPageFound,
  ADMIN_DOCTORS: ADMINDOCTORS,
  SUPERADMIN_DOCTORS: SUPERADMINDOCTORS,
  SUPERADMIN_FEES: SUPERADMINFEES,
  ADMIN_FEES: ADMINFEES,
  ADMIN_FORMS: SUPERADMINFORMS,
  SUPERADMIN_FORMS: SUPERADMINFORMS,
  SUPERADMIN_QUESTIONNAIRE: SUPERADMINQUESTIONNAIRE,
  ADMIN_QUESTIONNAIRE: ADMINQUESTIONNAIRE,
  DOCTOR_PRESCRIPTION_DASHBOARD: DOCTORPRESCRIPTIONDASHBOARD,
  DOCTOR_PRESCRIPTION_FORM: DOCTORPRESCRIPTIONFORM,
  TERMS_AND_CONDITIONS: TERMSANDCONDITIONS,
  PRIVACY_POLICY: PRIVACYPOLICY,
  SUPERADMIN_PLANS: SUPERADMINPLANS,
  SUPERADMIN_HOME_TESTING: SUPERADMINHOMETESTING,
  SUPERADMIN_STI_SCREENING: SUPERADMINSTISCREENING,
  ADMIN_PLANS: ADMINPLANS,
  DOCTOR_MEDICATION_FORM: DOCTORMEDICATIONFORM,
};
export default WEB_PAGES;
