const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const PATH = {
  //  AUTH PATHS
  SIGNIN: "/",
  SIGNUP: "/signup",
  FORGETPASSWORD: "/signin/forget-password",
  OTPCODE: "/login/otp-code",
  PRIVACYPOLICY: "/privacy-policy",
  TERMSANDCONDITION: "/terms-and-conditions",
  DOCTOR_DASHBOARD: "/doctor/dashboard",
  DOCTOR_PRESCRIPTION_DASHBOARD: "/doctor/online-prescription-questionnaire",
  DOCTOR_PRESCRIPTION_FORM: "/doctor/online-prescription-questionnaire/:patientName",
  DOCTOR_MEDICATION_FORM: "/doctor/online-prescription-questionnaire/medications",
  APPOINTMENT: "/doctor/appointment-diary",
  TELE_CONSULTATION: "/doctor/appointment-diary/tele-consultation",
  APPOINTMENT_NOTES: "/doctor/appointment-diary/tele-consultation/notes",
  PATIENTS: "/doctor/patients",
  STAFF: "/doctor/staff",
  CHAT: "/doctor/chat",
  DOCTOR_CHAT: "/doctor/update-chat",
  SLOTS: "/doctor/slots",
  ADMIN_SLOTS: "/admin/slots",
  ADMIN_CREATED_SLOTS: "/admin/slots/:doctorId",
  SUPERADMIN_SLOTS: "/superadmin/slots",
  SUPERADMIN_CREATED_SLOTS: "/superadmin/slots/:doctorId",
  NO_PAGE_FOUND: "*",
  PATIENTS_DETAILS: "/doctor/patients/:patientId/patient-details",
  APPOINTMENT_NOTES_DETAILS:
    "/doctor/patients/:patientId/patient-details/:appointmentId/appointment-notes",
  PROFILE: "/doctor/profile",
  STAFF_CHAT: "/doctor/staff/:staffId/chat",
  STAFF_SCREEN_CHAT: "/staff/chat",
  PROFILE_EDIT: "/profile/edit",
  PATIENT_DASHBOARD: "/patient/dashboard",
  PATIENT_OTPCODE: "/patient/login/otp-code",
  PATIENT_PRESCRIPTION: "/patient/prescriptions",
  PATIENT_PAYMENTDETAILS: "/patient/payment-receipt",
  PATIENT_APPOINTMENTDETAILS: "/patient/appointment-diary",
  PATIENT_TELE_CONSULTATION: "/patient/appointment-diary/tele-consultation",
  PATIENT_PROFILE: "/patient/profile",
  PATIENT_PRESCRIPTION_FORMS: "/patient/online-prescription-questionnaire",
  PATIENT_PLANS: "/patient/my-plans",
  PATIENT_PURCHASE_PLANS: "/patient/purchase-plans",
  PATIENT_PURCHASE_HOME_TESTING: "/patient/purchase-home-testing",
  PATIENT_PURCHASE_STI_SCREENING: "/patient/purchase-sti-screening",
  PATIENT_HOME_TESTING: "/patient/home-testing",
  PATIENT_STI_SCREENING: "/patient/STI-screening",
  // DOCTOR_LIST: "/patient/doctor-list",
  DOCTOR_DETAILS: "/patient/slots",
  SUPERADMIN_PAYMENTDETAILS: "/superadmin/payment-receipt",
  SUPERADMIN_SETTINGS: "/superadmin/settings",
  COMPLETE_PROFILE: "/patient/complete-profile",
  PERSONAL_INFORMATION: "/patient/complete-profile/personal-info",
  SUPERADMIN_DASHBOARD: "/superadmin/dashboard",
  ADMIN_DASHBOARD: "/admin/dashboard",
  SUPERADMIN_USERMANAGEMENT: "/superadmin/user-management",
  ADMIN_USERMANAGEMENT: "/admin/user-management",
  ADMIN_PAYMENTDETAILS: "/admin/payment-receipt",
  ADMIN_SETTINGS: "/admin/settings",
  MEDICAL_HISTORY: "/patient/complete-profile/medical-history",
  PHARMACY: "/patient/complete-profile/pharmacy",
  ADMIN_DOCTORS: "/admin/doctors",
  SUPERADMIN_DOCTORS: "/superadmin/doctors",
  ADMIN_FEES: "/admin/fees",
  SUPERADMIN_FEES: "/superadmin/fees",
  SUPERADMIN_FORMS: "/superadmin/forms",
  ADMIN_FORMS: "/admin/forms",
  ADMIN_QUESTIONNAIRE: "/admin/forms/questionnaire",
  SUPERADMIN_QUESTIONNAIRE: "/superadmin/forms/questionnaire",
  SUPERADMIN_PLANS: "/superadmin/subscription-plans",
  ADMIN_PLANS: "/admin/subscription-plans",
  SUPERADMIN_HOME_TESTING: "/superadmin/home-testing",
  SUPERADMIN_STI_SCREENING: "/superadmin/STI-screening",
}; 

export { ERROR, PATH };
