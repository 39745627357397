/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiLayoutGridFill } from "react-icons/ri";
import { HiUserAdd, HiUsers } from "react-icons/hi";
import { SiGoogleforms } from "react-icons/si";
import { TbTestPipe } from "react-icons/tb";
import { GiTestTubes } from "react-icons/gi";
import {
  BsCalendarFill,
  BsClockFill,
  BsFillCreditCardFill,
} from "react-icons/bs";
import { AiOutlineWechat } from "react-icons/ai";
import { IoIosGift } from "react-icons/io";
import { FaLayerGroup, FaUserMd } from "react-icons/fa";
import { LuPanelLeftClose } from "react-icons/lu";

import IMAGES from "../../assets/images";
import { PATH } from "../../utils/path.js";
import "../../assets/js/jquerry.slimscroll";

export default function Sidebar() {
  const { user } = useSelector((state) => state.auth);

  const location = useLocation();

  const getLinkClass = (path) => (location.pathname === path ? "active" : "");

  let sidebarContent;
  if (user?.roleId === 2) {
    sidebarContent = (
      <>
        <li className={getLinkClass(PATH.DOCTOR_DASHBOARD)}>
          <Link
            to={{
              pathname: PATH.DOCTOR_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.APPOINTMENT)}>
          <Link
            to={{
              pathname: PATH.APPOINTMENT,
            }}
          >
            <BsCalendarFill size={19} />
            <span>Appointment Diary</span>
          </Link>
        </li>

        <li className={getLinkClass(PATH.PATIENTS)}>
          <Link
            to={{
              pathname: PATH.PATIENTS,
            }}
          >
            <FaUserMd />
            <span>Patients</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.STAFF)}>
          <Link
            to={{
              pathname: PATH.STAFF,
            }}
          >
            <HiUserAdd />
            <span>Staff</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.CHAT)}>
          <Link
            to={{
              pathname: PATH.CHAT,
            }}
          >
            <AiOutlineWechat />
            <span>Chat</span>
          </Link>
        </li>
        {/* <li className={getLinkClass(PATH.DOCTOR_CHAT)}>
          <Link
            to={{
              pathname: PATH.DOCTOR_CHAT,
            }}
          >
            <AiOutlineWechat />
            <span>Update Chat</span>
          </Link>
        </li> */}
        <li className={getLinkClass(PATH.DOCTOR_PRESCRIPTION_DASHBOARD)}>
          <Link
            to={{
              pathname: PATH.DOCTOR_PRESCRIPTION_DASHBOARD,
            }}
          >
            <SiGoogleforms />
            <span className="text-wrap">Online Prescription</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 3) {
    sidebarContent = (
      <>
        <li className={getLinkClass(PATH.PATIENT_DASHBOARD)}>
          <Link
            to={{
              pathname: PATH.PATIENT_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.PATIENT_APPOINTMENTDETAILS)}>
          <Link
            to={{
              pathname: PATH.PATIENT_APPOINTMENTDETAILS,
            }}
          >
            <BsCalendarFill size={19} />
            <span>Appointment Diary</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.PATIENT_PAYMENTDETAILS)}>
          <Link
            to={{
              pathname: PATH.PATIENT_PAYMENTDETAILS,
            }}
          >
            <BsFillCreditCardFill size={22} />
            <span>Payment Receipt</span>
          </Link>
        </li>
        {/* <li className={getLinkClass(PATH.PATIENT_PRESCRIPTION)}>
          <Link
            to={{
              pathname: PATH.PATIENT_PRESCRIPTION,
            }}
          >
            <FaFilePrescription />
            <span>Prescriptions</span>
          </Link>
        </li> */}
        <li className={getLinkClass(PATH.DOCTOR_DETAILS)}>
          <Link
            to={{
              pathname: PATH.DOCTOR_DETAILS,
            }}
          >
            <FaLayerGroup size={20} />
            <span>Available Slots</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.PATIENT_PRESCRIPTION_FORMS)}>
          <Link
            to={{
              pathname: PATH.PATIENT_PRESCRIPTION_FORMS,
            }}
          >
            <SiGoogleforms />
            <span className="text-wrap">Request Prescription</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.PATIENT_PLANS)}>
          <Link
            to={{
              pathname: PATH.PATIENT_PLANS,
            }}
          >
            <IoIosGift />
            <span className="text-wrap">My Plans</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.PATIENT_HOME_TESTING)}>
          <Link
            to={{
              pathname: PATH.PATIENT_HOME_TESTING,
            }}
          >
            <TbTestPipe />
            <span className="text-wrap">Home Testing</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.PATIENT_STI_SCREENING)}>
          <Link
            to={{
              pathname: PATH.PATIENT_STI_SCREENING,
            }}
          >
            <GiTestTubes />
            <span className="text-wrap">STI Screening</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 1) {
    sidebarContent = (
      <>
        <li className={getLinkClass(PATH.SUPERADMIN_DASHBOARD)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_USERMANAGEMENT)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_USERMANAGEMENT,
              search: "?role=admin",
            }}
          >
            <BsCalendarFill size={19} />
            <span>User Management</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_PAYMENTDETAILS)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_PAYMENTDETAILS,
            }}
          >
            <BsFillCreditCardFill size={22} />
            <span>Payment Receipt</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_SLOTS)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_SLOTS,
            }}
          >
            <BsClockFill size={20} />
            <span>Scheduling</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_DOCTORS)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_DOCTORS,
            }}
          >
            <HiUserAdd size={24} />
            <span>Doctors</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_FORMS)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_FORMS,
            }}
          >
            <SiGoogleforms size={20} />
            <span>Forms</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_PLANS)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_PLANS,
            }}
          >
            <HiUsers />
            <span>Plans</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_HOME_TESTING)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_HOME_TESTING,
            }}
          >
            <TbTestPipe />
            <span className="text-wrap">Home Testing</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.SUPERADMIN_STI_SCREENING)}>
          <Link
            to={{
              pathname: PATH.SUPERADMIN_STI_SCREENING,
            }}
          >
            <GiTestTubes />
            <span className="text-wrap">STI Screening</span>
          </Link>
        </li>
      </>
    );
  } else if (user?.roleId === 4) {
    sidebarContent = (
      <>
        <li className={getLinkClass(PATH.ADMIN_DASHBOARD)}>
          <Link
            to={{
              pathname: PATH.ADMIN_DASHBOARD,
            }}
          >
            <RiLayoutGridFill />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.ADMIN_USERMANAGEMENT)}>
          <Link
            to={{
              pathname: PATH.ADMIN_USERMANAGEMENT,
              search: "?role=doctor",
            }}
          >
            <BsCalendarFill size={19} />
            <span>User Management</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.ADMIN_PAYMENTDETAILS)}>
          <Link
            to={{
              pathname: PATH.ADMIN_PAYMENTDETAILS,
            }}
          >
            <BsFillCreditCardFill size={22} />
            <span>Payment Receipt</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.ADMIN_SLOTS)}>
          <Link
            to={{
              pathname: PATH.ADMIN_SLOTS,
            }}
          >
            <BsClockFill size={20} />
            <span>Scheduling</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.ADMIN_DOCTORS)}>
          <Link
            to={{
              pathname: PATH.ADMIN_DOCTORS,
            }}
          >
            <HiUserAdd size={24} />
            <span>Doctors</span>
          </Link>
        </li>
        {/* <li className={getLinkClass(PATH.ADMIN_FORMS)}>
          <Link
            to={{
              pathname: PATH.ADMIN_FORMS,
            }}
          >
            <SiGoogleforms size={20} />
            <span>Forms</span>
          </Link>
        </li>
        <li className={getLinkClass(PATH.ADMIN_PLANS)}>
          <Link
            to={{
              pathname: PATH.ADMIN_PLANS,
            }}
          >
            <HiUsers />
            <span>Plans</span>
          </Link>
        </li> */}
      </>
    );
  } else if (user?.roleId === 5) {
    sidebarContent = (
      <li className={getLinkClass(PATH.STAFF_SCREEN_CHAT)}>
        <Link
          to={{
            pathname: PATH.CHAT,
          }}
        >
          <AiOutlineWechat />
          <span>Chat</span>
        </Link>
      </li>
    );
  }
  return (
    <div className="sidebar px-3" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <div className="header-left my-4 d-flex justify-content-between align-items-center pageName">
            <div className="d-flex flex-grow-1 justify-content-center d-lg-flex">
              <Link
                to={PATH.DASHBOARD}
                className="logo mr-0 header-logo-image text-decoration-none"
              >
                <img alt="sidebar logo" src={IMAGES.SIDEBAR_LOGO} height={40} />
              </Link>
            </div>
            <div
              id="close-nav-btn"
              className="d-md-block d-lg-none rounded-circle bg-none shadow-sm p-2 cursor-pointer"
            >
              <LuPanelLeftClose color="#F16422" size={22} />
            </div>
          </div>

          <ul>{sidebarContent}</ul>
        </div>
      </div>
    </div>
  );
}
